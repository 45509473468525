import React from 'react'
import styled from 'styled-components'

import Layout from '../templates/Layout'
import { BACKGROUND, PRIMARY, SECONDARY } from '../constants/colors'
import { Container } from '../components/shared'
import { withErrorHandler } from '../components/errorHandler'

const Title = styled.div`
  color: ${SECONDARY};
  font-size: 30px;
  margin: 30px 0px;
`

const Subtitle = styled.div`
  color: ${SECONDARY};
  font-size: 24px;
  margin: 24px 0px;
`

const Body = styled.div`
  color: ${PRIMARY};
  font-size: 18px;
  margin: 15px 0px;
`

const Header = styled.div`
  color: ${SECONDARY};
  font-size: 36px;
  line-height: 48px;
  margin-top: 48px;
  margin-bottom: 36px;
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${BACKGROUND};
`

const Wrap = styled.div`
  max-width: 80%;
  margin-bottom: 48px;
`

const Terms = () => (
  <Layout>
    <Container>
      <Flex>
        <Wrap>
          <Header>Blue Light View Terms of Service Agreement</Header>
          <Body>Last Updated: September 25, 2020</Body>
          <Body>
            This Blue Light Terms of Service Agreement (“Agreement”) is between
            you and Blue Light Holdings Inc. (for the purposes hereof, “Blue
            Light,” “we” or “us”). The Agreement governs your use of the “Blue
            Light Service,” an online platform that allows consumers like you to
            view video, photo and written/text programs (“Programs”) from
            content owners and licensors (“Producers”) through Producer-branded
            websites and applications that we power. Notice: Section 10 of this
            Agreement contains a mandatory ARBITRATION AGREEMENT. By using the
            Blue Light Service, you agree that any claims you may have against
            Blue Light relating to the Blue Light Service must be ARBITRATED,
            and you waive the right to (1) assert claims against Blue Light in
            court; (2) participate in a class action; and (3) have a jury hear
            your case.
          </Body>
          <Title>1. Acceptance</Title>
          <Body>
            By creating an account, viewing videos, making a purchase,
            downloading Producer-branded applications that we power, or
            otherwise visiting or using the Blue Light Service, you accept this
            Agreement and consent to contract with us electronically.
          </Body>
          <Body>
            If you are an entity other than a natural person, the person who
            registers the account or otherwise uses the Blue Light Service must
            have the authority to bind the entity. In this context, “you” means
            both the entity and each person who is authorized to access the
            account.
          </Body>
          <Body>
            We may update this Agreement by posting a revised version on our
            website. By continuing to use the Blue Light Service, you accept any
            revised Agreement.
          </Body>
          <Body>
            You may terminate the Agreement at any time by deleting your
            account. We reserve the right to suspend or terminate your account
            for your breach of the terms hereof. If Blue Light deletes your
            account for breach, you may not re-register. In the event of any
            termination or expiration of the Agreement, the following sections
            will survive: Section 7 (Disclaimers), Section 8 (Limitation of
            Liability), Section 9 (Indemnification), Section 10 (Arbitration
            Agreement; Jury Waiver; Class Action Waiver), and Section 11
            (General Provisions).
          </Body>
          <Title>2. Accounts</Title>
          <Body>
            Registration: You must create an account to use certain features we
            offer (e.g., to view a Program). To do so, you must provide an email
            address. By creating an account, you agree to receive notices from
            Blue Light and the Producers of the Programs you purchase at this
            email address.
          </Body>
          <Body>
            Age Requirements: You may not create an account if you are younger
            than 16 years of age. By creating an account, you represent that you
            are at least 16 years of age, and, to the extent required by law,
            you have the permission of a parent or guardian to create an account
            and use the Blue Light Service.
          </Body>
          <Body>
            Parents and Guardians: By granting your child permission to use the
            Blue Light Service through your account, you agree and understand
            that you are responsible for monitoring and supervising your
            child&apos;s usage. If you believe your child is using your account
            and does not have your permission, please contact us immediately so
            that we can disable access.
          </Body>
          <Body>
            Account Security: You are responsible for all activity that occurs
            under your account, including unauthorized activity. You must
            safeguard the confidentiality of your account credentials. If you
            are using a computer that others have access to, you must log out of
            your account after each session. If you become aware of unauthorized
            access to your account, you must change your password and notify us
            immediately.
          </Body>
          <Title>3. Privacy</Title>
          <Body>
            Please review our Privacy Policy to learn about the information we
            collect from you when you use the Blue Light Service, how we use it,
            and with whom we share it.
          </Body>
          <Body>
            By creating an account, you agree that the Producer of the
            Program(s) you purchase will have access to your account information
            and account activities, such as the viewing and purchase of the
            Producer’s Programs, and your contact information. Blue Light is not
            responsible for a Producer’s use or disclosure of your data.
          </Body>
          <Title>4. Program Viewing and Purchase Options</Title>
          <Body>
            Producers may offer the following purchase options through
            Producer-branded websites and applications: i.) Rental: After paying
            the stated fee, you may stream or view the Program an unlimited
            number of times during the stated rental period ii.) Purchase: After
            paying the stated fee, you may stream or view the Program an
            unlimited number of times; if offered by the Producer, you may
            download the Program iii.) Subscription: For a recurring fee, you
            may stream the or view Programs offered within a Producer’s
            subscription channel an unlimited number of times during your
            subscription period. The Producer may add or remove Programs from
            the channel at any time.
          </Body>
          <Body>
            To make a purchase, you must provide a valid payment method. We may
            apply taxes, including VAT (value-added tax), to any charges. Prices
            and other terms of purchase are subject to change. If a Producer
            provides you access to a Program on a free basis, such access will
            be deemed a “purchase” for the purpose of this Agreement.
          </Body>
          <Body>
            ALL PURCHASES, INCLUDING RECURRING CHARGES, ARE FINAL AND
            NON-REFUNDABLE.
          </Body>
          <Body>
            Free trials and Discounts: Producers may offer free-trial or
            discounted subscriptions. When a free-trial period ends, your paid
            subscription begins (unless you have cancelled) and you must pay the
            full monthly or annual fee. If a Producer provides a discount for
            the first subscription period, you must pay the discounted fee; in
            any renewal, you must pay the full fee.
          </Body>
          <Body>
            Automatic Renewal: To the extent permitted by applicable law,
            subscriptions automatically renew for the subscription period until
            you cancel. Your payment method will be charged at the beginning of
            each subscription period. If a Purchaser offers a free-trial period,
            your payment method will be charged at the end of the free-trial
            period unless you cancel before the end of that period.
          </Body>
          <Body>
            How to Decline Renewal: To pause or cancel a subscription, visit
            your ’Manage Account’ page. Your changes will be applied to your
            next subscription period.
          </Body>
          <Body>
            In-App Purchase: Producers may allow you to purchase subscriptions
            or goods within apps you download. To turn off automatic renewal for
            subscriptions, access your platform’s account settings (not Blue
            Light’s). Any billing inquiries should be directed to the app
            platform.
          </Body>
          <Title>5. Licenses and Intellectual Property</Title>
          <Body>
            License to Programs: Subject to the terms hereof, you have the right
            to stream, view and/or (if offered) download for your personal
            entertainment purposes: (i) Programs that you have rented during the
            stated rental period; (ii) Programs that you have purchased; and
            (iii) Programs made available within a channel that you have
            subscribed to during your subscription period.
          </Body>
          <Body>
            Restrictions: You may not resell streams or downloads, use any
            Program for any commercial purpose, redistribute or retransmit any
            Program, publicly perform or display any Program, or make derivative
            works from any Program. All rights not expressly granted herein are
            reserved by the applicable Producer.
          </Body>
          <Body>
            Blue Light Service: Subject to the terms hereof, Blue Light grants
            you permission to use the Blue Light Service for the sole purpose of
            viewing Programs for your personal entertainment. All rights not
            expressly granted by Blue Light are reserved.
          </Body>
          <Body>
            Producer Apps: If you download a Producer application that we power
            (an “app”) that operates on a third-party platform (e.g., iOS,
            Roku): (i) you have the right to use such app for your personal,
            non-commercial use on devices permitted by the platform operator;
            (ii) as between Blue Light and the platform operator (but without
            creating any obligation by Blue Light), Blue Light shall be
            responsible for the apps and their content and providing any
            warranty, support, or indemnification with respect to such apps as
            required by law; and (iii) the platform operator shall have the
            right to enforce the terms of this clause with respect to your use
            of the app.
          </Body>
          <Body>
            Your Content; Feedback: To the extent that you submit any content to
            Blue Light (i.e. in your profile, a comment, or forum), you grant
            Blue Light an irrevocable, worldwide, non-exclusive, royalty-free,
            perpetual right and license to use, copy, adapt, transmit,
            distribute, license, and publicly perform and display such content
            in all media for any purpose whatsoever. Any content submitted must
            comply with the Acceptable Use Policy set forth in Section 6. If you
            make suggestions to Blue Light on improving our products or
            services, Blue Light may use your suggestions without any
            compensation to you.
          </Body>
          <Title>6. Acceptable Use Policy</Title>
          <Body>
            We may allow you to upload, submit, or publish (collectively, to
            &quot;submit&quot;) content such as images and text (collectively,
            &quot;content&quot;). You must ensure that your content, and your
            conduct, complies with the Acceptable Use Policy set forth in this
            Section 6. Blue Light may (but is not obligated to) monitor your
            account, content, and conduct, regardless of your privacy settings.
            Blue Light may take all appropriate actions to enforce this
            Acceptable Use Policy including removing specific videos or
            suspending or removing your account.
          </Body>
          <Subtitle>6.1 Copyright Policy</Subtitle>
          <Body>
            You may only upload content that you have the right to upload and
            share. Copyright owners may send Blue Light a takedown notice if
            they believe Blue Light is hosting infringing materials. We will, in
            appropriate circumstances, terminate the accounts of persons who
            infringe.
          </Body>
          <Subtitle>6.2 Content Restrictions</Subtitle>
          <Body>
            You may not submit any content that infringes any third party’s
            copyrights or other rights (e.g., trademark, privacy rights, etc.),
            is sexually explicit (e.g., pornography) or proposes a transaction
            of a sexual nature, is hateful, defamatory, or discriminatory or
            incites hatred against any individual or group, promotes or supports
            terror or hate groups, exploits minors, depicts unlawful acts or
            extreme violence, provides instructions on how to assemble
            explosive/incendiary devices or homemade/improvised firearms,
            depicts animal cruelty or extreme violence towards animals, promotes
            fraudulent or dubious business schemes or proposes an unlawful
            transaction, makes false or misleading claims about vaccination
            safety, claims that mass tragedies are hoaxes or false flag
            operations, depicts or encourages self-harm, or violates any
            applicable law.
          </Body>
          <Subtitle>6.3 Code of Conduct</Subtitle>
          <Body>
            In using the Blue Light Service, you may not use an offensive screen
            name (e.g., explicit language) or avatar (e.g., containing nudity),
            act in a deceptive manner or impersonate any person or organization,
            harass or stalk any person, harm or exploit minors, distribute
            “spam” in any form or use misleading metadata, collect personal
            information about others, access another’s account without
            permission, engage in any unlawful activity, or cause or encourage
            others to do any of the above.
          </Body>
          <Subtitle>6.4 Prohibited Technical Measures</Subtitle>
          <Body>
            You will not except as authorized by law or as permitted by us:
            scrape, reproduce, redistribute, create derivative works from,
            decompile, reverse engineer, alter, archive, or disassemble any part
            of the Blue Light Service; or attempt to circumvent any of our
            security, rate-limiting, filtering, or digital rights management
            measures submit any malicious program, script, or code, submit an
            unreasonable number of requests to our servers; or take any other
            actions to manipulate, interfere with, or damage the Blue Light
            Service.
          </Body>
          <Subtitle>6.5 Restricted Users</Subtitle>
          <Body>
            You may not create an account if you are a member of a terror or
            hate group. You may not purchase any goods or services from us if
            you reside in a country subject to a comprehensive U.S. sanctions
            program or are a Specifically Designated National (SDN) as
            designated by the U.S. Department of the Treasury.
          </Body>
          <Title>7. Disclaimers</Title>
          <Body>
            YOUR USE OF THE BLUE LIGHT SERVICE AND ANY PROGRAM IS AT YOUR OWN
            RISK. BLUE LIGHT PROVIDES THE BLUE LIGHT SERVICE ON AN “AS IS” AND
            “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS
            OR IMPLIED. PRODUCERS PROVIDE PROGRAMS ON AN “AS IS” AND “AS
            AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
            IMPLIED. BLUE LIGHT AND EACH PRODUCER DISCLAIM ALL WARRANTIES,
            EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT.
          </Body>
          <Body>
            Without limiting the above, Blue Light makes no representations or
            warranties concerning the availability of the Blue Light Service in
            a particular jurisdiction, the availability of the Blue Light
            Service for any particular device, operating system, or browser, the
            continued support for a particular feature of the Blue Light
            Service, the viewing quality of any Program. Blue Light does not
            guarantee that you will be able to use the Blue Light Service at all
            times, or view any Program uninterrupted, error-free or displayed at
            any particular resolution, the quality of your viewing experience
            depends on a number of factors, including your viewing device and
            the quality of your Internet connection, the content of any Program
            or the suitability of any Program for any audience. All opinions and
            statements expressed by or in Programs (or related materials) are
            those of the Producer and/or persons involved in the production of
            the Program, not Blue Light, the continued availability of any
            Program or the availability of particular Program within a
            subscription channel. Programs may be withdrawn at any time without
            notice, any Producer’s actions or omissions with respect to your
            personal information.
          </Body>
          <Body>
            Producers may provide links to other Producer websites or
            third-party websites. Blue Light is not responsible for the content
            of, or any interactions or transactions that may take place on or
            through, any such website.
          </Body>
          <Title>8. Limitation of Liability</Title>
          <Body>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, BLUE LIGHT, ITS DIRECTORS,
            OFFICERS, EMPLOYEES, REPRESENTATIVES, CONSULTANTS, AGENTS,
            SUPPLIERS, AND PARTNERS (INCLUDING PRODUCERS) SHALL NOT BE LIABLE
            FOR (I) ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
            EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL,
            USE, DATA, OR OTHER INTANGIBLE LOSSES; (II) DAMAGES RELATING TO ANY
            DISPUTE BETWEEN YOU AND A PRODUCER OR OTHER BLUE LIGHT USER; OR
            (III) DAMAGES EXCEEDING THE GREATER OF USD $100 OR THE AMOUNTS PAID
            BY YOU TO BLUE LIGHT OVER THE 12 MONTHS PRECEDING THE FILING OF YOUR
            CLAIM.
          </Body>
          <Body>
            The foregoing limitations apply to all claims, whether based on
            warranty, contract, tort, or any other legal theory, whether or not
            Blue Light (or a Producer) has been informed of the possibility of
            such damage, and whether a remedy set forth herein is found to have
            failed its essential purpose. Some jurisdictions may not allow the
            exclusion of certain warranties or the exclusion or limitation of
            liability as set forth above, so these limitations above may not
            apply to you.
          </Body>
          <Title>9. Indemnification</Title>
          <Body>
            You shall indemnify, defend, and hold harmless Blue Light and its
            directors, officers, employees, representatives, consultants,
            agents, suppliers, and partners (including Producers) from any
            liability, claim, demand, damages, losses, and costs (including
            attorneys’ fees) arising from: (i) your unauthorized use of any
            Program; (ii) your breach of this Agreement or violation of any law
            or third-party right; or (iii) any content that you upload or submit
            to us.
          </Body>
          <Title>
            10. Arbitration Agreement; Jury Waiver; Class Action Waiver
          </Title>
          <Body>
            This Section 10 sets forth a binding arbitration agreement between
            you and Blue Light (the “Arbitration Agreement”). In this
            Arbitration Agreement, you agree to arbitrate all claims relating to
            the Blue Light Service, to waive your right to a trial by jury, and
            to waive any right to proceed on a class basis in arbitration or
            otherwise.
          </Body>
          <Subtitle>10.1 Arbitration Agreement</Subtitle>
          <Body>
            The exclusive means of resolving any dispute or claim arising out of
            or relating to this Agreement (including any alleged breach
            thereof), or your use of the Blue Light Service shall be BINDING
            ARBITRATION administered by JAMS pursuant to its Streamlined
            Arbitration Rules & Procedures.
          </Body>
          <Subtitle>10.2 Overview</Subtitle>
          <Body>
            Arbitration provides a private dispute resolution process that is
            usually more streamlined and less formal than litigation. In an
            arbitration, your rights will be determined by a neutral third party
            called an arbitrator, and not a judge or jury. Both you and Blue
            Light are entitled to fundamentally fair proceedings at every stage
            of the arbitration, including the hearing (if any). The arbitrator
            will decide all issues relating to the dispute, including the
            question of arbitrability, and can grant any relief that a court
            could grant. Decisions by the arbitrator are enforceable in court
            and may be overturned by a court only for very limited reasons.
          </Body>
          <Subtitle>10.3 Location of Hearing</Subtitle>
          <Body>
            If you are a consumer, as defined by JAMS in its Consumer Minimum
            Standards, you may request that the arbitration hearing be conducted
            in the area in which you reside. Otherwise, the hearing (if any)
            shall take place in Los Angeles County, California, United States of
            America.
          </Body>
          <Subtitle>10.4 Class-Action Waiver</Subtitle>
          <Body>
            EACH PARTY WAIVES ITS RIGHT TO GO TO COURT, TO A TRIAL BY JURY, AND
            TO PARTICIPATE IN A CLASS ACTION, CLASS ARBITRATION, OR OTHER
            REPRESENTATIVE PROCEEDING WITH RESPECT TO ANY CLAIM SUBJECT TO
            ARBITRATION.
          </Body>
          <Subtitle>10.5 Exception for Small Claims Court Matters</Subtitle>
          <Body>
            Notwithstanding the above, each party has the right to bring an
            individual claim against the other in a small claims court of
            competent jurisdiction pursuant to Rule 1 of JAMS’ Consumer Minimum
            Standards. If one party files an arbitration that could be litigated
            in such a small claims court, the responding party may request that
            the dispute proceed in small claims court. If the responding party
            requests to proceed in small claims court before the appointment of
            the arbitrator, the arbitration shall be administratively closed. If
            requested after the appointment of the arbitrator, the arbitrator
            shall administratively close the arbitration so long as the
            proceedings are at an early stage and no hearing has occurred.
          </Body>
          <Subtitle>10.6 Jurisdiction and Venue</Subtitle>
          <Body>
            The Federal Arbitration Agreement (“FAA”) shall govern this
            Arbitration Agreement. To the extent not preempted or inconsistent
            with the FAA, the choice of law provisions in Section 11 are
            incorporated and are applicable to this Arbitration Agreement. Any
            arbitration award may be entered in a court of competent
            jurisdiction.
          </Body>
          <Subtitle>10.7 Binding Arbitration with Producers</Subtitle>
          <Body>
            To the extent that the Producer has agreed to resolve disputes with
            consumers through arbitration in its agreements with Blue Light, you
            agree that any dispute with a Producer arising out of or relating to
            your purchase or viewing of a Producer’s Program or subscription
            channel shall be resolved by BINDING ARBITRATION administered by
            JAMS in accordance with this Section 10.
          </Body>
          <Title>11. General Provisions</Title>
          <Body>
            Choice of Law: Any disputes relating to this Agreement or your use
            of the Blue Light Service will be governed by the laws of California
            and the United States of America (with respect to matters subject to
            federal jurisdiction such as copyright), without regard to
            principles of conflicts of law. The Uniform Commercial Code, the
            Uniform Computer Information Transaction Act, and the United Nations
            Convention of Controls for International Sale of Goods shall not
            apply.
          </Body>
          <Body>
            Choice of Venue for Litigation; Jury Trial Waiver: Any proceeding to
            enforce the Arbitration Agreement, including any proceeding to
            confirm, modify, or vacate an arbitration award, may be commenced in
            any court of competent jurisdiction. In the event that the
            Arbitration Agreement is for any reason held to be unenforceable,
            any litigation (except for small-claims court actions) shall be
            commenced only in the federal or state courts located in Los Angeles
            County, California. You hereby irrevocably consent to the exclusive
            jurisdiction of those courts for such purposes and, to the extent
            permitted by law, both Blue Light and you WAIVE ANY RIGHT TO A TRIAL
            BY JURY in such an action.
          </Body>
          <Body>
            No Waiver: The failure of Blue Light (or any Producer) to exercise
            or enforce any term of this Agreement will not constitute a waiver
            of such term.
          </Body>
          <Body>
            Provisions Severable: If any term of this Agreement is held invalid
            or unenforceable, that term will be construed in a manner consistent
            with applicable law to reflect, as nearly as possible, the original
            intentions of the parties, and the remaining terms will remain in
            full force and effect.
          </Body>
          <Body>
            No Third Parties: No third parties shall have any rights under this
            Agreement, except that Producers may enforce the terms herein
            against you, and indemnified parties may enforce indemnification
            rights.
          </Body>
          <Body>
            Force Majeure: Neither Blue Light nor any Producer shall be liable
            for any failure or delay in performance of its obligations under
            this Agreement arising out of or caused, directly or indirectly, by
            circumstances beyond its reasonable control, including, without
            limitation, acts of God; natural disasters; wars; civil or military
            disturbances; sabotage; strikes; epidemics; riots; power failures;
            computer failure; loss or malfunction of utility, transportation,
            computer (hardware or software) or telephone communication service;
            accidents; labor disputes, acts of civil or military authority;
            governmental actions; or inability to obtain labor, material,
            equipment or transportation.
          </Body>
          <Body>
            U.S. Export Compliance: You may not use the Blue Light Service if
            (i) you are personally subject to U.S. government sanctions or (ii)
            you are located in a country that is subject to U.S. Government
            sanctions such that your use of the Blue Light Service would violate
            U.S. law. You represent and warrant that you are not subject to such
            sanctions.
          </Body>
          <Body>
            Entire Agreement: This Agreement incorporates by reference the Blue
            Light Privacy Policy. Except for links to these documents, links to
            pages on the Blue Light website or any third party materials are for
            convenience only and do not form part of this Agreement.
          </Body>
          <Body>
            This Agreement sets forth the entire understanding between you and
            Blue Light concerning your use of the Blue Light Service and
            supersedes all prior agreements regarding the same. This Agreement
            may only be modified by us. Any changes will be posted to the Blue
            Light website and will apply prospectively.
          </Body>
          <Body>
            Contact Us: If you have any support questions, please contact us at{' '}
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>
            . You must send any notices of a legal nature to us at:
          </Body>
          <Body>Blue Light Holdings Inc.</Body>
          <Body>918 9th Street, Unit E</Body>
          <Body>Santa Monica, CA 90403</Body>
          <Body>
            <a href="mailto:support@solinfitness.com">
              support@solinfitness.com
            </a>
          </Body>
        </Wrap>
      </Flex>
    </Container>
  </Layout>
)

export default withErrorHandler(Terms)
